<template>
  <div
    class="relative transition cursor-pointer ring-1 ring-inset before:absolute before:content pointer-events-auto"
    v-tooltip="{
      show: (labelShown || allLabelsShown) && objectData.type !== 'Schaduwlat',
      content: `${objectData.isCornerElement ? 'H' : elementId}`,
      trigger: 'manual',
      placement: orientation === 'vertical' ? 'right' : 'top',
    }"
    :style="styleObject"
    :class="{
      'before:-right-1 before:-left-1 before:top-0 before:bottom-0':
        wallIndex === 0 || wallIndex === 3,
      'before:-top-1 before:-bottom-1 before:right-0 before:left-0':
        wallIndex === 1 || wallIndex === 2,
      'bg-teal-500 hover:bg-teal-600 ring-teal-400': objectData.color
        ? objectData.color === 'teal'
        : objectData.type === 'Absorber',
      'bg-purple-500 hover:bg-purple-600 ring-purple-400': objectData.color
        ? objectData.color === 'purple'
        : objectData.type === 'CurveDiffuser',
      'bg-blue-500 hover:bg-blue-600 ring-blue-400': objectData.color
        ? objectData.color === 'blue'
        : objectData.type === 'Flatdiffuser',
      'bg-lime-500 hover:bg-lime-600 ring-lime-400': objectData.color
        ? objectData.color === 'lime'
        : objectData.type === 'v10 panel',
      'bg-red-500 hover:bg-red-600 ring-red-400': objectData.color
        ? objectData.color === 'red'
        : objectData.type === 'Reflector',
      'bg-fuchsia-500 hover:bg-fuchsia-600 ring-fuchsia-400': objectData.color
        ? objectData.color === 'fuchsia'
        : objectData.type === 'Visual',
      'bg-gray-500 hover:bg-gray-600 ring-gray-400': objectData.color
        ? objectData.color === 'gray'
        : objectData.type === 'Leeg' ||
          objectData.type === 'Schaduwlat' ||
          objectData.type === 'Schaduwblok',
      'bg-yellow-500 hover:bg-yellow-600 ring-yellow-400': objectData.color
        ? objectData.color === 'yellow'
        : objectData.type === 'Anders',
    }"
    @mouseenter="hoverObject(true)"
    @mouseleave="hoverObject(false)"
    @click="editObject(wallIndex, objectIndex)"
  >
    <div
      class="absolute z-10 bg-white"
      :class="{
        'right-full w-80 h-full': wallIndex === 0,
        'left-full w-80 h-full': wallIndex === 3,
        'top-full h-80 w-full': wallIndex === 2,
        'bottom-full h-80 w-full': wallIndex === 1,
      }"
      v-if="
        objectData.otherType
          ? objectData.otherType.toLowerCase().includes('deur') ||
            objectData.otherType.toLowerCase().includes('raam') ||
            objectData.otherType.toLowerCase().includes('doorgang')
          : false || objectData.isInfrontOfOpening
      "
    ></div>
    <div
      v-if="objectData.type === 'CurveDiffuser' && !objectData.isCornerElement"
      class="absolute z-[0] overflow-hidden"
      :class="{
        'left-full h-full': wallIndex === 0,
        'right-full h-full': wallIndex === 3,
        'bottom-full w-full': wallIndex === 2,
        'top-full w-full': wallIndex === 1,
      }"
    >
      <div
        class="bg-purple-400"
        :class="{
          '-translate-x-1/2 h-full aspect-2-5 right-curve': wallIndex === 0,
          'translate-x-1/2 h-full aspect-2-5 left-curve': wallIndex === 3,
          'translate-y-1/2 w-full aspect-5-2 top-curve': wallIndex === 2,
          '-translate-y-1/2 w-full aspect-5-2 bottom-curve': wallIndex === 1,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";

export default {
  name: "WallPanel",
  props: ["objectData", "objectIndex", "orientation", "wallIndex"],
  data() {
    return {
      labelShown: false,
    };
  },
  computed: {
    walls: get("walls"),
    roomWidth: get("roomWidth"),
    roomLength: get("roomLength"),
    gapFront: get("gapFront"),
    gapRight: get("gapRight"),
    gapLeft: get("gapLeft"),
    gapBack: get("gapBack"),
    panelSpace: get("panelSpace"),
    autoSizedObjectsSize: get("autoSizedObjectsSize"),
    objectToEdit: sync("objectToEdit"),
    showEditPanelModal: sync("showEditPanelModal"),
    allLabelsShown: get("allLabelsShown"),
    elementId() {
      if (this.wallIndex === 0) {
        return `V${this.objectIndex + 1}`;
      } else if (this.wallIndex === 1) {
        return `R${this.objectIndex + 1}`;
      } else if (this.wallIndex === 2) {
        return `L${this.objectIndex + 1}`;
      } else {
        return `A${this.objectIndex + 1}`;
      }
    },
    styleObject() {
      const object = this.objectData;
      if (this.orientation === "vertical") {
        if (object.size === "custom") {
          return {
            flex: `0 0 ${(object.otherSize / this.panelSpace("vertical")) *
              100}%`,
            width: `${(object.thickness /
              (this.roomLength - this.gapFront - this.gapBack)) *
              100}%`,
          };
        } else if (object.size === "auto") {
          return {
            flex: "1 1 auto",
            width: `${(object.thickness /
              (this.roomLength - this.gapFront - this.gapBack)) *
              100}%`,
          };
        } else {
          return {
            flex: `0 0 ${(object.size / this.panelSpace("vertical")) * 100}%`,
            width: `${(object.thickness /
              (this.roomLength - this.gapFront - this.gapBack)) *
              100}%`,
          };
        }
      } else {
        if (object.size === "custom") {
          return {
            height: `${(object.thickness /
              (this.roomWidth - this.gapLeft - this.gapRight)) *
              100}%`,
            flex: `0 0 ${(object.otherSize / this.panelSpace("horizontal")) *
              100}%`,
          };
        } else if (object.size === "auto") {
          return {
            flex: "1 1 auto",
            height: `${(object.thickness /
              (this.roomWidth - this.gapLeft - this.gapRight)) *
              100}%`,
          };
        } else {
          return {
            height: `${(object.thickness /
              (this.roomWidth - this.gapLeft - this.gapRight)) *
              100}%`,
            flex: `0 0 ${(object.size / this.panelSpace("horizontal")) * 100}%`,
          };
        }
      }
    },
    objectSize() {
      const object = this.objectData;
      if (object.size === "custom") {
        return object.otherSize;
      } else if (object.size === "auto") {
        return this.autoSizedObjectsSize(this.wallIndex);
      } else {
        return object.size;
      }
    },
  },
  methods: {
    editObject(wallIndex, objectIndex) {
      this.objectToEdit = {
        objectIndex,
        wallIndex,
        ...this.walls[wallIndex].objects[objectIndex],
      };
      this.showEditPanelModal = true;
    },
    hoverObject(bool) {
      this.labelShown = bool;
      this.$store.commit("hoverObject", {
        objectData: this.objectData,
        bool,
      });
    },
  },
};
</script>
