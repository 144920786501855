<template>
  <div class="flex-1 max-w-7xl">
    <div
      class="overflow-hidden relative"
      :style="{ paddingTop: `${roomRatio * 100}%` }"
    >
      <div
        class="absolute inset-0 w-full h-full bg-gray-100 border border-gray-300 shadow-inner"
      >
        <div
          class="absolute bg-white ring-1 ring-inset ring-gray-300"
          :style="{
            top: `${(gapRight / roomWidth) * 100}%`,
            left: `${(gapFront / roomLength) * 100}%`,
            right: `${(gapBack / roomLength) * 100}%`,
            bottom: `${(gapLeft / roomWidth) * 100}%`,
          }"
        >
          <div
            class="absolute inset-0 flex items-stretch justify-end w-full h-full"
          >
            <div
              v-for="(podest, index) in podestenReversed"
              :key="index"
              :style="{
                width: `${(podest.width / panelSpace('horizontal')) * 100}%`,
              }"
              class="absolute flex items-start justify-start h-full pl-3"
            >
              <div class="absolute inset-0 w-full h-full border-l-2"></div>
              <div
                class="flex flex-col items-start text-xs font-semibold text-gray-500 whitespace-nowrap mt-16"
              >
                <div>{{ podest.name }}</div>
                <div>{{ podest.width }} cm</div>
              </div>
            </div>
          </div>
          <div
            v-if="
              curveDiffusersVoorwand !== -1 &&
                voorwandCurveDiffuserVlakkeAfwerking === false
            "
            class="absolute top-0 left-0 bg-purple-400 pointer-events-none rounded-br-full"
            :style="{
              width: `${(curveDiffusersVoorwand / panelSpace('horizontal')) *
                100}%`,
              height: `${(curveDiffusersVoorwand / panelSpace('vertical')) *
                100}%`,
            }"
          />
          <div
            v-if="
              curveDiffusersVoorwand !== -1 &&
                voorwandCurveDiffuserVlakkeAfwerking
            "
            class="absolute top-0 left-0 pointer-events-none"
            :style="{
              width: `${(curveDiffusersVoorwand / panelSpace('horizontal')) *
                100}%`,
              height: `${(curveDiffusersVoorwand / panelSpace('vertical')) *
                100}%`,
            }"
          >
            <img src="/img/triangle-green.png" />
          </div>
          <div
            v-if="
              curveDiffusersVoorwand !== -1 &&
                voorwandCurveDiffuserVlakkeAfwerking === false
            "
            class="absolute bottom-0 left-0 bg-purple-400 pointer-events-none rounded-tr-full"
            :style="{
              width: `${(curveDiffusersVoorwand / panelSpace('horizontal')) *
                100}%`,
              height: `${(curveDiffusersVoorwand / panelSpace('vertical')) *
                100}%`,
            }"
          />
          <div
            v-if="
              curveDiffusersVoorwand !== -1 &&
                voorwandCurveDiffuserVlakkeAfwerking
            "
            class="absolute bottom-0 left-0 pointer-events-none -rotate-90"
            :style="{
              width: `${(curveDiffusersVoorwand / panelSpace('horizontal')) *
                100}%`,
              height: `${(curveDiffusersVoorwand / panelSpace('vertical')) *
                100}%`,
            }"
          >
            <img src="/img/triangle-green.png" />
          </div>
          <div
            v-if="
              curveDiffusersAchterwand !== -1 &&
                achterwandCurveDiffuserVlakkeAfwerking === false
            "
            class="absolute top-0 right-0 bg-purple-400 pointer-events-none rounded-bl-full"
            :style="{
              width: `${(curveDiffusersAchterwand / panelSpace('horizontal')) *
                100}%`,
              height: `${(curveDiffusersAchterwand / panelSpace('vertical')) *
                100}%`,
            }"
          />
          <div
            v-if="
              curveDiffusersAchterwand !== -1 &&
                achterwandCurveDiffuserVlakkeAfwerking
            "
            class="absolute top-0 right-0 pointer-events-none rotate-90"
            :style="{
              width: `${(curveDiffusersAchterwand / panelSpace('horizontal')) *
                100}%`,
              height: `${(curveDiffusersAchterwand / panelSpace('vertical')) *
                100}%`,
            }"
          >
            <img src="/img/triangle-green.png" />
          </div>
          <div
            v-if="
              curveDiffusersAchterwand !== -1 &&
                achterwandCurveDiffuserVlakkeAfwerking === false
            "
            class="absolute bottom-0 right-0 bg-purple-400 pointer-events-none rounded-tl-full"
            :style="{
              width: `${(curveDiffusersAchterwand / panelSpace('horizontal')) *
                100}%`,
              height: `${(curveDiffusersAchterwand / panelSpace('vertical')) *
                100}%`,
            }"
          />
          <div
            v-if="
              curveDiffusersAchterwand !== -1 &&
                achterwandCurveDiffuserVlakkeAfwerking
            "
            class="absolute bottom-0 right-0 pointer-events-none rotate-180"
            :style="{
              width: `${(curveDiffusersAchterwand / panelSpace('horizontal')) *
                100}%`,
              height: `${(curveDiffusersAchterwand / panelSpace('vertical')) *
                100}%`,
            }"
          >
            <img src="/img/triangle-green.png" />
          </div>
          <wall-panels />
          <surround-sound-indicators />
          <div
            class="absolute top-1/2 -translate-y-1/2 translate-x-1/2 text-center flex items-center justify-center text-xs font-semibold text-gray-500 pointer-events-none"
            :style="{
              width: `${(300 / panelSpace('horizontal')) * 100}%`,
              height: `${(300 / panelSpace('vertical')) * 100}%`,
              right: `${sweetspotPosition}%`,
            }"
          >
            <div>
              <div>
                Sweetspot
              </div>
              <div>
                {{
                  Math.round(
                    (panelSpace("horizontal") * sweetspotPosition) / 100
                  )
                }}
                cm
              </div>
            </div>
          </div>
        </div>
        <div
          class="absolute inset-0 flex items-center bg-gray-500 bg-opacity-60 z-20"
          v-show="ceilingShown && ceilingType === 'Latten'"
        >
          <div
            class="absolute"
            :style="{
              width: `${(ceilingLength / roomLength) * 100}%`,
              height: `${(ceilingWidth / roomWidth) * 100}%`,
              left: `${(ceilingDistanceToFront / roomLength) * 100}%`,
            }"
          >
            <div
              class="flex items-stretch justify-between h-full"
              v-show="ceilingType === 'Latten'"
            >
              <div
                class="bg-white rounded-sm drop-shadow"
                v-for="(lat, index) in latten"
                :key="index"
                :style="{ width: `${(lat / ceilingLength) * 100}%` }"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="absolute inset-0 grid content-center bg-gray-500 bg-opacity-60 z-20"
          v-show="ceilingShown && ceilingType === 'Cassette'"
          :style="{
            'grid-template-rows': `repeat(${cassetteRows}, ${(cassetteWidth /
              roomWidth) *
              100}%)`,
            'grid-template-columns': `${(ceilingDistanceToFront / roomLength) *
              100}% repeat(${cassetteColumns}, ${(cassetteLength / roomLength) *
              100}%)`,
            'grid-row-gap': `${(cassetteGapY / roomWidth) * 100}%`,
            'grid-column-gap': `${(cassetteGapX / roomLength) * 100}%`,
          }"
        >
          <div
            v-for="i in cassetteRows * cassetteColumns"
            :key="i"
            class="w-full h-full bg-white border rounded-sm drop-shadow"
            :style="{
              'grid-column-start':
                (i - 1) % cassetteColumns === 0 ? 2 : 'initial',
            }"
          ></div>
        </div>
        <div
          class="absolute inset-0 grid content-center bg-gray-500 bg-opacity-60"
          v-show="ceilingShown && ceilingType === 'Absorptiepanelen'"
          :style="{
            'grid-template-rows': `repeat(${absorptionPanelsRows}, ${(absorptionPanelsWidth /
              roomWidth) *
              100}%)`,
            'grid-template-columns': `${(ceilingDistanceToFront / roomLength) *
              100}% repeat(${absorptionPanelsColumns}, ${(absorptionPanelsLength /
              roomLength) *
              100}%)`,
            'grid-row-gap': `${(absorptionPanelsDistanceBetween / roomWidth) *
              100}%`,
            'grid-column-gap': `${(absorptionPanelsDistanceBetween /
              roomLength) *
              100}%`,
          }"
        >
          <div
            v-for="i in absorptionPanelsRows * absorptionPanelsColumns"
            :key="i"
            class="w-full h-full bg-white border rounded-sm drop-shadow"
            :style="{
              'grid-column-start':
                (i - 1) % absorptionPanelsColumns === 0 ? 2 : 'initial',
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

import WallPanels from "../WallPanels";
import SurroundSoundIndicators from "../SurroundSoundIndicators";

export default {
  components: {
    WallPanels,
    SurroundSoundIndicators,
  },
  computed: {
    roomLength: get("roomLength"),
    roomWidth: get("roomWidth"),
    roomRatio: get("roomRatio"),
    gapFront: get("gapFront"),
    gapRight: get("gapRight"),
    gapLeft: get("gapLeft"),
    gapBack: get("gapBack"),
    panelSpace: get("panelSpace"),
    ceilingShown: get("ceilingShown"),
    ceilingType: get("ceilingType"),
    ceilingWidth: get("ceilingWidth"),
    ceilingLength: get("ceilingLength"),
    ceilingDistanceToFront: get("ceilingDistanceToFront"),
    absorptionPanelsSize: get("absorptionPanelsSize"),
    podesten: get("podesten"),
    voorwandCurveDiffuserVlakkeAfwerking: get(
      "voorwandCurveDiffuserVlakkeAfwerking"
    ),
    achterwandCurveDiffuserVlakkeAfwerking: get(
      "achterwandCurveDiffuserVlakkeAfwerking"
    ),
    podestenReversed() {
      return this.podesten.slice().reverse();
    },
    absorptionPanelsWidth() {
      return this.absorptionPanelsSize.split("x")[0];
    },
    absorptionPanelsLength() {
      return this.absorptionPanelsSize.split("x")[1];
    },
    absorptionPanelsRows: get("absorptionPanelsRows"),
    absorptionPanelsColumns: get("absorptionPanelsColumns"),
    absorptionPanelsOrientation: get("absorptionPanelsOrientation"),
    absorptionPanelsDistanceBetween: get("absorptionPanelsDistanceBetween"),
    curveDiffusersVoorwand: get("curveDiffusersVoorwand"),
    curveDiffusersAchterwand: get("curveDiffusersAchterwand"),
    latten: get("lattenList"),
    cassetteRows: get("cassetteRows"),
    cassetteColumns: get("cassetteColumns"),
    cassetteWidth: get("cassetteWidth"),
    cassetteLength: get("cassetteLength"),
    cassetteGapY: get("cassetteGapY"),
    cassetteGapX: get("cassetteGapX"),
    surroundSound: get("surroundSound"),
    sweetspotPosition: get("sweetspotPosition"),
  },
};
</script>
