<template>
  <label class="block" :for="name">
    <div class="text-sm font-semibold text-gray-300">{{ label }}</div>
    <div class="relative mt-1">
      <input
        class="w-full px-3 py-1 text-sm font-semibold leading-6 transition border border-gray-600 rounded focus:border-primary-600 ring-primary-600 focus:ring-1 focus:outline-none"
        :class="{
          'tabular-nums text-right': type === 'number',
          'pl-3': prepend === '',
          'pr-3': append === '',
          'pl-9': prepend !== '',
          'pr-9': append !== '',
          'bg-gray-700': readonly === false,
          'bg-gray-800': readonly === true,
        }"
        :id="name"
        :type="type"
        :value="value"
        @input="
          $emit(
            'input',
            type === 'number'
              ? Number($event.target.value)
              : $event.target.value
          )
        "
        :readonly="readonly"
      />
      <div
        class="absolute top-0 bottom-0 right-0 flex items-center h-full pr-3 text-xs font-semibold text-gray-400 pointer-events-none"
        v-if="append !== ''"
      >
        {{ append }}
      </div>
    </div>
  </label>
</template>

<script>
export default {
  name: "InputGroup",
  props: {
    name: String,
    label: String,
    type: String,
    value: [String, Number, Boolean],
    prepend: {
      default: "",
      type: String,
    },
    append: {
      default: "",
      type: String,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
