<template>
  <modal-wrapper @close="$emit('close')">
    <div
      class="grid w-full max-w-xl overflow-hidden text-white transition bg-gray-800 bg-opacity-75 border-l border-gray-700 shadow-xl cursor-auto"
      style="grid-template-rows: 1fr auto"
    >
      <div class="overflow-y-auto pb-6">
        <div class="flex items-center justify-between px-6 mt-3">
          <div class="text-lg font-bold">Voeg een nieuw object toe</div>
          <button
            class="p-2 transition rounded focus:outline-none hover:bg-gray-700"
            @click="$emit('close')"
          >
            <times-icon class="w-6 h-6" />
          </button>
        </div>
        <div class="px-6 mt-8">
          <div>
            <div class="text-sm font-semibold text-gray-300">Wand</div>
            <div
              class="flex flex-wrap gap-1 p-1 mt-1 bg-gray-700 border border-gray-600 rounded"
            >
              <label
                :for="`wall${wall}`"
                v-for="wall in walls"
                :key="wall"
                class="relative flex items-center justify-center flex-1 px-1.5 py-1 text-sm font-semibold leading-5 text-center transition rounded cursor-pointer"
                :class="{
                  'bg-gray-700 text-gray-300': selectedWall !== wall,
                  'bg-primary-600 shadow text-white': selectedWall === wall,
                }"
              >
                {{ wall }}
                <input
                  type="radio"
                  name="wall"
                  :id="`wall${wall}`"
                  class="absolute invisible w-0 h-0"
                  :value="wall"
                  v-model="selectedWall"
                />
              </label>
            </div>
          </div>
          <div class="mt-8">
            <div class="text-sm font-semibold text-gray-300">Objecttype</div>
            <div
              class="grid grid-cols-5 gap-1 p-1 mt-1 bg-gray-700 border border-gray-600 rounded"
            >
              <label
                :for="`objectType${objectType}`"
                v-for="objectType in objectTypes"
                :key="objectType"
                class="relative flex items-center justify-center flex-1 w-full px-1.5 py-1 text-sm font-semibold leading-5 text-center transition rounded cursor-pointer"
                :class="{
                  'bg-gray-700 text-gray-300':
                    selectedObjectType !== objectType,
                  'bg-primary-600 shadow text-white':
                    selectedObjectType === objectType,
                }"
              >
                {{ objectType }}
                <input
                  type="radio"
                  name="objectType"
                  :id="`objectType${objectType}`"
                  class="absolute invisible w-0 h-0"
                  :value="objectType"
                  v-model="selectedObjectType"
                />
              </label>
            </div>
            <input-group
              v-show="selectedObjectType === 'Anders'"
              class="w-48 mt-3"
              type="text"
              label="Namelijk"
              name="otherObjectType"
              v-model="otherObjectType"
            />
          </div>
          <div class="mt-8">
            <div class="text-sm font-semibold text-gray-300">
              Breedte
              {{
                selectedObjectSize === "auto"
                  ? "(" + nextAutoObjectSize + " cm)"
                  : ""
              }}
            </div>
            <div
              class="grid grid-cols-8 gap-1 p-1 mt-1 bg-gray-700 border border-gray-600 rounded"
            >
              <label
                :for="`objectSize${size.value}`"
                v-for="size in objectSizes"
                :key="size.value"
                class="relative flex items-center justify-center flex-1 w-full px-1.5 py-1 text-sm font-semibold leading-5 text-center transition rounded cursor-pointer"
                :class="{
                  'bg-gray-700 text-gray-300':
                    selectedObjectSize !== size.value,
                  'bg-primary-600 shadow text-white':
                    selectedObjectSize === size.value,
                }"
              >
                {{ size.label }}
                <input
                  type="radio"
                  name="size"
                  :id="`objectSize${size.value}`"
                  class="absolute invisible w-0 h-0"
                  :value="size.value"
                  v-model="selectedObjectSize"
                />
              </label>
            </div>
            <input-group
              v-show="selectedObjectSize === 'custom'"
              class="w-24 mt-3"
              type="number"
              append="cm"
              label="Namelijk"
              name="otherObjectSize"
              v-model="otherObjectSize"
            />
          </div>
          <div class="mt-8">
            <div class="grid grid-cols-2 gap-4">
              <input-group
                name="thickness"
                label="Diepte"
                type="number"
                v-model.number="thickness"
                append="cm"
              />
              <input-group
                name="height"
                label="Hoogte"
                type="number"
                v-model.number="height"
                append="cm"
              />
            </div>
          </div>
          <div class="mt-8">
            <div class="flex flex-col">
              <checkbox
                v-model="isInfrontOfOpening"
                label="Object bevindt zich voor een opening"
                name="isInfrontOfOpening"
              />
            </div>
          </div>
          <div class="mt-8">
            <div>
              <label for="color" class="text-sm font-semibold text-gray-300"
                >Kleur</label
              >
              <div class="text-xs font-semibold text-gray-400">
                Optioneel; wanneer niet ingevuld kiest het systeem zelf een
                kleur.
              </div>
            </div>
            <div>
              <div
                class="grid grid-cols-8 gap-3 p-3 mt-2 bg-gray-700 border border-gray-600 rounded"
              >
                <label
                  :for="`color-${color}`"
                  v-for="color in colorOptions"
                  :key="color"
                  class="relative flex items-center justify-center flex-1 w-full py-4 text-sm font-semibold leading-5 text-center transition rounded-sm cursor-pointer ring-offset-gray-700"
                  :class="{
                    'bg-teal-500 ring-teal-400': color === 'teal',
                    'bg-purple-500 ring-purple-400': color === 'purple',
                    'bg-blue-500 ring-blue-400': color === 'blue',
                    'bg-lime-500 ring-lime-400': color === 'lime',
                    'bg-red-500 ring-red-400': color === 'red',
                    'bg-fuchsia-500 ring-fuchsia-400': color === 'fuchsia',
                    'bg-gray-500 ring-gray-400': color === 'gray',
                    'bg-yellow-500 ring-yellow-400': color === 'yellow',
                    'ring-2 ring-offset-4': color === selectedColor,
                  }"
                >
                  <input
                    type="radio"
                    name="color"
                    :id="`color-${color}`"
                    class="absolute invisible w-0 h-0"
                    :value="color"
                    v-model="selectedColor"
                    @click="toggleColorIfNeeded(color)"
                  />
                </label>
              </div>
            </div>
          </div>
          <div class="mt-8">
            <div class="flex flex-col">
              <label
                for="objectNote"
                class="text-sm font-semibold text-gray-300"
                >Notitie</label
              >
              <textarea
                class="w-full px-3 py-1 mt-1 text-sm font-semibold leading-6 transition bg-gray-700 border border-gray-600 rounded focus:border-primary-600 ring-primary-600 focus:ring-1 focus:outline-none"
                id="objectNote"
                rows="3"
                v-model="objectNote"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-between px-6 py-3 bg-gray-700 border-t border-gray-600"
      >
        <div
          class="flex items-center text-sm font-semibold text-red-500 transition rounded-full"
          :class="{
            'opacity-0': errorMessage === '',
            'opacity-1': errorMessage !== '',
          }"
        >
          <exclamation-icon class="w-5 h-5" />
          <div class="ml-2 leading-5">{{ errorMessage }}</div>
        </div>
        <div class="space-x-2">
          <button
            class="px-3 py-2 text-sm font-semibold leading-4 text-white bg-primary-600 border-b-2 border-primary-800 rounded shadow focus:outline-none"
            @click="checkAndAddObjectToWall"
          >
            Toevoegen
          </button>
          <button
            class="px-3 py-2 text-sm font-semibold leading-4 text-gray-900 bg-gray-300 border-b-2 border-gray-400 rounded shadow focus:outline-none"
            @click="$emit('close')"
          >
            Annuleren
          </button>
        </div>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import { get } from "vuex-pathify";

import ModalWrapper from "./ModalWrapper";
import InputGroup from "./elements/InputGroup";
import ExclamationIcon from "./icons/ExclamationIcon";
import TimesIcon from "./icons/TimesIcon";
import Checkbox from "./elements/Checkbox";

export default {
  name: "CreateObjectModal",
  props: ["shown"],
  components: {
    ModalWrapper,
    InputGroup,
    ExclamationIcon,
    TimesIcon,
    Checkbox,
  },
  computed: {
    baseThickness: get("panelWidth"),
    baseHeight: get("panelHeight"),
    preferredWall: get("preferredWall"),
    nextAutoObjectSize() {
      if (
        this.$store.getters.amountOfAutoSizedObjects(this.selectedWall) === 0
      ) {
        return this.$store.getters.availableSpace(this.selectedWall);
      } else {
        return (
          Math.round(
            ((this.$store.getters.autoSizedObjectsSize(this.selectedWall) *
              this.$store.getters.amountOfAutoSizedObjects(this.selectedWall)) /
              (this.$store.getters.amountOfAutoSizedObjects(this.selectedWall) +
                1)) *
              10
          ) / 10
        );
      }
    },
    objectSizes() {
      return [
        {
          label: "Auto",
          value: "auto",
        },
        {
          label: `${this.$store.getters.defaultPanelWidth} cm`,
          value: this.$store.getters.defaultPanelWidth,
        },
        {
          label: "30 cm",
          value: 30,
        },
        {
          label: "40 cm",
          value: 40,
        },
        {
          label: "60 cm",
          value: 60,
        },
        {
          label: "90 cm ",
          value: 90,
        },
        {
          label: "120 cm",
          value: 120,
        },
        { label: "Anders", value: "custom" },
      ];
    },
  },
  data() {
    return {
      selectedWall: null,
      selectedObjectType: null,
      otherObjectType: "",
      selectedObjectSize: null,
      otherObjectSize: "",
      selectedColor: "",
      thickness: 0,
      height: 0,
      isInfrontOfOpening: false,
      objectNote: "",
      errorMessage: "",
      walls: ["Voorwand", "Zijwand rechts", "Zijwand links", "Achterwand"],
      objectTypes: [
        "Absorber",
        "CurveDiffuser",
        "Flatdiffuser",
        "v10 panel",
        "Reflector",
        "Visual",
        "Leeg",
        "Schaduwlat",
        "Anders",
      ],
      colorOptions: [
        "teal",
        "purple",
        "blue",
        "lime",
        "red",
        "fuchsia",
        "yellow",
        "gray",
      ],
    };
  },
  watch: {
    selectedObjectType(value) {
      if (value !== "Anders") {
        this.otherObjectType = "";
      }
    },
    selectedObjectSize(value) {
      if (value !== "custom") {
        this.otherObjectSize = "";
      }
    },
  },
  methods: {
    toggleColorIfNeeded(color) {
      if (this.selectedColor === color) {
        this.selectedColor = null;
      }
    },
    checkAndAddObjectToWall() {
      if (this.selectedWall === null) {
        this.errorMessage = "Geen wand gekozen";
      } else if (this.selectedObjectType === null) {
        this.errorMessage = "Geen paneeltype gekozen";
      } else if (this.selectedObjectSize === null) {
        this.errorMessage = "Geen afmeting gekozen";
      } else if (
        this.selectedObjectType === "Anders" &&
        this.otherObjectType === ""
      ) {
        this.errorMessage = "Geen objecttype ingevoerd";
      } else if (
        this.selectedObjectSize === "custom" &&
        this.otherObjectSize === ""
      ) {
        this.errorMessage = "Geen afmeting ingevoerd";
      } else {
        this.$store.commit("addObjectToWall", {
          object: {
            hovered: false,
            id: new Date().getTime(),
            thickness: this.thickness,
            height: this.height,
            type: this.selectedObjectType,
            otherType: this.otherObjectType,
            size: this.selectedObjectSize,
            otherSize: this.otherObjectSize,
            note: this.objectNote,
            color: this.selectedColor,
            isInfrontOfOpening: this.isInfrontOfOpening,
          },
          wallName: this.selectedWall,
        });
        this.$emit("close");
      }
    },
  },
  created() {
    this.thickness = this.baseThickness;
    this.height = this.baseHeight;
    if (this.preferredWall !== null) {
      this.selectedWall = this.walls[this.preferredWall];
    }
  },
};
</script>
