<template>
  <div
    class="fixed inset-0 z-20 flex items-stretch justify-end w-full h-full bg-black cursor-pointer bg-opacity-80"
    @click.self="$emit('close')"
  >
    <transition name="slide-in-left" appear>
      <slot />
    </transition>
  </div>
</template>

<script>
export default {
  name: "ModalWrapper"
};
</script>
