var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"block",attrs:{"for":_vm.name}},[_c('div',{staticClass:"text-sm font-semibold text-gray-300"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"relative mt-1"},[_c('input',{staticClass:"w-full px-3 py-1 text-sm font-semibold leading-6 transition border border-gray-600 rounded focus:border-primary-600 ring-primary-600 focus:ring-1 focus:outline-none",class:{
        'tabular-nums text-right': _vm.type === 'number',
        'pl-3': _vm.prepend === '',
        'pr-3': _vm.append === '',
        'pl-9': _vm.prepend !== '',
        'pr-9': _vm.append !== '',
        'bg-gray-700': _vm.readonly === false,
        'bg-gray-800': _vm.readonly === true,
      },attrs:{"id":_vm.name,"type":_vm.type,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"input":function($event){_vm.$emit(
          'input',
          _vm.type === 'number'
            ? Number($event.target.value)
            : $event.target.value
        )}}}),(_vm.append !== '')?_c('div',{staticClass:"absolute top-0 bottom-0 right-0 flex items-center h-full pr-3 text-xs font-semibold text-gray-400 pointer-events-none"},[_vm._v(" "+_vm._s(_vm.append)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }