<template>
  <div class="overflow-y-auto bg-gray-800 border-r border-gray-600 w-96">
    <accordion
      label="Projectgegevens"
      :openStatus="openAccordion"
      @toggle="setAccordion"
    >
      <div class="space-y-3">
        <input-group
          name="projectName"
          label="Projectnaam"
          type="text"
          v-model="projectName"
        />
        <input-group
          name="clientName"
          label="Klantnaam"
          type="text"
          v-model="clientName"
        />
        <input-group
          name="projectDate"
          label="Datum"
          type="text"
          v-model="projectDate"
        />
        <input-group
          name="version"
          label="Versie"
          type="text"
          v-model="version"
        />
        <div>
          <label
            for="projectDescription"
            class="text-sm font-semibold text-gray-300"
            >Beschrijving</label
          >
          <textarea
            class="w-full px-3 py-1 mt-1 text-sm font-semibold leading-6 transition bg-gray-700 border border-gray-600 rounded focus:border-primary-600 ring-primary-600 focus:ring-1 focus:outline-none"
            id="projectDescription"
            rows="3"
            v-model="projectDescription"
          ></textarea>
        </div>
      </div>
    </accordion>
    <accordion
      label="Basisafmetingen"
      :openStatus="openAccordion"
      @toggle="setAccordion"
    >
      <div class="text-sm font-semibold">Afmetingen v/d ruimte</div>
      <div class="grid grid-cols-2 gap-3 mt-3">
        <input-group
          name="roomLength"
          label="Lengte"
          type="number"
          v-model.number="roomLength"
          append="cm"
        />
        <input-group
          name="roomWidth"
          label="Breedte"
          type="number"
          v-model.number="roomWidth"
          append="cm"
        />
        <input-group
          name="roomWidth"
          label="Hoogte"
          type="number"
          v-model.number="roomHeight"
          append="cm"
        />
      </div>
      <div class="text-sm font-semibold mt-9">Absorptie achter de panelen</div>
      <div class="grid grid-cols-2 gap-3 mt-3">
        <input-group
          name="gapFront"
          label="Voorwand"
          type="number"
          v-model.number="gapFront"
          append="cm"
        />
        <input-group
          name="gapSides"
          label="Zijwand rechts"
          type="number"
          v-model.number="gapRight"
          append="cm"
        />
        <input-group
          name="gapSides"
          label="Zijwand links"
          type="number"
          v-model.number="gapLeft"
          append="cm"
        />
        <input-group
          name="gapBack"
          label="Achterwand"
          type="number"
          v-model.number="gapBack"
          append="cm"
        />
      </div>
      <div class="text-sm font-semibold mt-9">Basisafmetingen objecten</div>
      <div class="flex mt-3 space-x-3">
        <input-group
          name="defaultPanelWidth"
          label="Objectbreedte"
          type="number"
          v-model.number="defaultPanelWidth"
          append="cm"
        />
        <input-group
          name="panelWidth"
          label="Objectdikte"
          type="number"
          v-model.number="panelWidth"
          append="cm"
        />
        <input-group
          name="panelWidth"
          label="Objecthoogte"
          type="number"
          v-model.number="panelHeight"
          append="cm"
        />
      </div>
      <div class="mt-3">
        <input-group
          name="sweetspotPosition"
          label="Sweetspotpositie"
          type="number"
          v-model.number="sweetspotPosition"
          append="%"
        />
      </div>
    </accordion>
    <accordion
      label="Hoeken"
      :openStatus="openAccordion"
      @toggle="setAccordion"
    >
      <select-group
        label="Voorwand"
        :options="[
          { label: 'Leeg', value: -1 },
          { label: 'CurveDiffusers 60 cm (42 cm)', value: 42 },
          { label: 'CurveDiffusers 90 cm (64 cm)', value: 64 },
          { label: 'CurveDiffusers 120 cm (84 cm)', value: 84 },
          { label: 'Schaduwblokken', value: 5.5 },
        ]"
        class="w-full"
        type="number"
        v-model="curveDiffusersVoorwand"
      />
      <checkbox
        class="mt-2"
        label="Vlakke afwerking"
        name="voorwandCurveDiffuserVlakkeAfwerking"
        v-model="voorwandCurveDiffuserVlakkeAfwerking"
        v-if="curveDiffusersVoorwand !== 5.5 && curveDiffusersVoorwand !== -1"
      />
      <select-group
        label="Achterwand"
        :options="[
          { label: 'Leeg', value: -1 },
          { label: 'CurveDiffusers 60 cm (42 cm)', value: 42 },
          { label: 'CurveDiffusers 90 cm (64 cm)', value: 64 },
          { label: 'CurveDiffusers 120 cm (84 cm)', value: 84 },
          { label: 'Schaduwblokken', value: 5.5 },
        ]"
        class="w-full mt-4"
        type="number"
        v-model="curveDiffusersAchterwand"
      />
      <checkbox
        class="mt-2"
        label="Vlakke afwerking"
        name="achterwandCurveDiffuserVlakkeAfwerking"
        v-model="achterwandCurveDiffuserVlakkeAfwerking"
        v-if="
          curveDiffusersAchterwand !== 5.5 && curveDiffusersAchterwand !== -1
        "
      />
    </accordion>
    <accordion
      :label="wall.name"
      :openStatus="openAccordion"
      @toggle="setAccordion"
      v-for="(wall, wallIndex) in walls"
      :key="wallIndex"
    >
      <template v-slot:head>
        <div
          class="text-xs font-semibold leading-5 tabular-nums"
          :class="{
            'text-gray-300':
              wallIndex === 0 || wallIndex === 3
                ? totalObjectSize(wallIndex) < panelSpace('vertical')
                : totalObjectSize(wallIndex) < panelSpace('horizontal'),
            'text-red-500':
              wallIndex === 0 || wallIndex === 3
                ? totalObjectSize(wallIndex) > panelSpace('vertical')
                : totalObjectSize(wallIndex) > panelSpace('horizontal'),
            'text-green-500':
              wallIndex === 0 || wallIndex === 3
                ? totalObjectSize(wallIndex) === panelSpace('vertical')
                : totalObjectSize(wallIndex) === panelSpace('horizontal'),
          }"
        >
          {{ totalObjectSize(wallIndex) }}/{{
            wallIndex === 0 || wallIndex === 3
              ? panelSpace("vertical")
              : panelSpace("horizontal")
          }}
          cm
        </div>
      </template>
      <div class="space-y-3">
        <div
          class="flex justify-between px-3 py-2 mb-3 bg-gray-800 border-t border-b border-l-4 border-r border-gray-600 rounded"
          :class="{
            'border-l-purple-500': curveDiffusersVoorwand !== 5.5,
            'border-l-gray-700': curveDiffusersVoorwand === 5.5,
          }"
          v-if="curveDiffusersVoorwand !== -1 && wallIndex !== 3"
        >
          <div class="text-sm font-semibold text-white">
            {{
              curveDiffusersVoorwand === 5.5 ? "Schaduwblok" : "CurveDiffuser"
            }}
          </div>
          <div class="text-sm font-semibold text-gray-300 tabular-nums">
            {{ curveDiffusersVoorwand }} cm
          </div>
        </div>
        <div
          class="flex justify-between px-3 py-2 mb-3 bg-gray-800 border-t border-b border-l-4 border-r border-gray-600 rounded"
          :class="{
            'border-l-purple-500': curveDiffusersAchterwand !== 5.5,
            'border-l-gray-700': curveDiffusersAchterwand === 5.5,
          }"
          v-if="curveDiffusersAchterwand !== -1 && wallIndex === 3"
        >
          <div class="text-sm font-semibold text-white">
            {{
              curveDiffusersAchterwand === 5.5 ? "Schaduwblok" : "CurveDiffuser"
            }}
          </div>
          <div class="text-sm font-semibold text-gray-300 tabular-nums">
            {{ curveDiffusersAchterwand }} cm
          </div>
        </div>
        <draggable
          v-model="wall.objects"
          :group="wall.name"
          :animation="100"
          easing="cubic-bezier(1, 0, 0, 1)"
          ghost-class="opacity-0"
          tag="div"
          class="space-y-3"
        >
          <object-row
            v-for="(object, objectIndex) in wall.objects"
            :object-data="object"
            :wall-index="wallIndex"
            :object-index="objectIndex"
            :key="object.id"
            @edit="editObject(wallIndex, objectIndex)"
            @duplicate="duplicateObject(wallIndex, objectIndex)"
            @delete="deleteObject(objectIndex, wall)"
          />
        </draggable>
        <div
          class="flex justify-between px-3 py-2 mb-3 bg-gray-800 border-t border-b border-l-4 border-r border-gray-600 rounded"
          :class="{
            'border-l-purple-500': curveDiffusersAchterwand !== 5.5,
            'border-l-gray-700': curveDiffusersAchterwand === 5.5,
          }"
          v-if="curveDiffusersAchterwand !== -1 && wallIndex !== 0"
        >
          <div class="text-sm font-semibold text-white">
            {{
              curveDiffusersAchterwand === 5.5 ? "Schaduwblok" : "CurveDiffuser"
            }}
          </div>
          <div class="text-sm font-semibold text-gray-300 tabular-nums">
            {{ curveDiffusersAchterwand }} cm
          </div>
        </div>
        <div
          class="flex justify-between px-3 py-2 mb-3 bg-gray-800 border-t border-b border-l-4 border-r border-gray-600 rounded"
          :class="{
            'border-l-purple-500': curveDiffusersVoorwand !== 5.5,
            'border-l-gray-700': curveDiffusersVoorwand === 5.5,
          }"
          v-if="curveDiffusersVoorwand !== -1 && wallIndex === 0"
        >
          <div class="text-sm font-semibold text-white">
            {{
              curveDiffusersVoorwand === 5.5 ? "Schaduwblok" : "CurveDiffuser"
            }}
          </div>
          <div class="text-sm font-semibold text-gray-300 tabular-nums">
            {{ curveDiffusersVoorwand }} cm
          </div>
        </div>
        <div class="flex justify-between">
          <base-button
            icon="PlusIcon"
            @click="
              showCreatePanelModal = true;
              preferredWall = wallIndex;
            "
            >Object toevoegen</base-button
          >
          <button
            class="flex items-center p-2 ml-auto text-white bg-primary-600 border-b-2 border-primary-800 rounded shadow focus:outline-none"
            @click="duplicateWall('rightToLeft')"
            v-if="wallIndex === 1"
            v-tooltip="'Dupliceer naar zijwand links'"
          >
            <duplicate-icon class="w-5 h-5" />
            <arrow-right-icon class="w-4 h-4 ml-1" />
          </button>
          <button
            class="flex items-center p-2 text-white bg-primary-600 border-b-2 border-primary-800 rounded shadow focus:outline-none"
            @click="duplicateWall('leftToRight')"
            v-if="wallIndex === 2"
            v-tooltip="'Dupliceer naar zijwand rechts'"
          >
            <arrow-left-icon class="w-4 h-4" />
            <duplicate-icon class="w-5 h-5 ml-1" />
          </button>
        </div>
      </div>
    </accordion>
    <accordion
      label="Podesten"
      :openStatus="openAccordion"
      @toggle="setAccordion"
    >
      <div
        class="overflow-hidden border border-gray-600 divide-y divide-gray-600 rounded"
      >
        <div
          class="flex items-start justify-between p-3 text-sm font-semibold"
          v-for="(podest, index) in podesten"
          :key="index"
        >
          <div class="flex flex-col">
            <input-group
              label="Naam"
              v-model="podest.name"
              type="text"
              :name="'podestName' + index"
            />
            <input-group
              class="mt-3"
              label="Afmeting"
              v-model="podest.width"
              type="number"
              :name="'podestWidth' + index"
              append="cm"
            />
          </div>
          <base-button
            :icon-only="true"
            :transparent="true"
            icon="TrashIcon"
            @click="deletePodest(index)"
          />
        </div>
      </div>
      <base-button class="mt-4" icon="PlusIcon" @click="addPodest"
        >Podest toevoegen</base-button
      >
    </accordion>
    <accordion
      label="Plafond"
      :openStatus="openAccordion"
      @toggle="setAccordion"
    >
      <div class="flex space-x-4">
        <select-group
          class="flex-1"
          label="Plafondtype"
          v-model="ceilingType"
          :options="ceilingOptions"
        />
        <select-group
          class="flex-1"
          label="Koof"
          v-model="koof"
          :options="koofOptions"
          v-if="ceilingType === 'Latten' || ceilingType === 'Cassette'"
        />
      </div>
      <div class="flex mt-4 space-x-4" v-show="ceilingType === 'Latten'">
        <input-group
          label="Breedte"
          v-model="ceilingWidth"
          name="ceilingWidth"
          type="number"
          append="cm"
        />
        <input-group
          label="Lengte"
          v-model="ceilingLength"
          name="ceilingLength"
          type="number"
          append="cm"
        />
      </div>
      <div v-if="ceilingType === 'Cassette'">
        <div class="flex mt-4 space-x-4">
          <input-group
            label="Cassettebreedte"
            v-model="cassetteWidth"
            name="cassetteWidth"
            type="number"
            append="cm"
          />
          <input-group
            label="Cassettelengte"
            v-model="cassetteLength"
            name="cassetteLength"
            type="number"
            append="cm"
          />
        </div>
        <div class="flex mt-4 space-x-4">
          <input-group
            label="Rijen"
            v-model="cassetteRows"
            name="cassetteRows"
            type="number"
          />
          <input-group
            label="Kolommen"
            v-model="cassetteColumns"
            name="cassetteColumns"
            type="number"
          />
        </div>
        <div class="flex mt-4 space-x-4">
          <input-group
            label="Tussenruimte per rij"
            v-model="cassetteGapY"
            name="cassetteGapY"
            type="number"
            append="cm"
          />
          <input-group
            label="Tussenruimte per kolom"
            v-model="cassetteGapX"
            name="cassetteGapX"
            type="number"
            append="cm"
          />
        </div>
      </div>
      <div
        class="flex mt-4 space-x-4"
        v-if="ceilingType === 'Absorptiepanelen'"
      >
        <input-group
          label="Rijen"
          v-model="absorptionPanelsRows"
          name="absorptionPanelsRows"
          type="number"
        />
        <input-group
          label="Kolommen"
          v-model="absorptionPanelsColumns"
          name="absorptionPanelsColumns"
          type="number"
        />
      </div>
      <div
        class="flex mt-4 space-x-4"
        v-if="ceilingType === 'Absorptiepanelen'"
      >
        <select-group
          class="flex-1"
          label="Afmeting"
          v-model="absorptionPanelsSize"
          :options="absorptionPanelsSizeOptions"
        />
        <input-group
          class="flex-1"
          label="Afstand tussen"
          v-model="absorptionPanelsDistanceBetween"
          name="absorptionPanelsDistanceBetween"
          type="number"
          append="cm"
        />
      </div>
      <input-group
        class="mt-4"
        label="Afstand tot voorwand"
        v-model="ceilingDistanceToFront"
        name="ceilingDistanceToFront"
        type="number"
        append="cm"
      />
    </accordion>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";

import Draggable from "vuedraggable";

import Accordion from "../elements/Accordion";
import InputGroup from "../elements/InputGroup";
import BaseButton from "../elements/BaseButton";
import Checkbox from "../elements/Checkbox.vue";
import SelectGroup from "../SelectGroup";
import ObjectRow from "../ObjectRow";

import ArrowLeftIcon from "../icons/ArrowLeftIcon";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import DuplicateIcon from "../icons/DuplicateIcon";

export default {
  components: {
    Draggable,
    Accordion,
    InputGroup,
    BaseButton,
    Checkbox,
    SelectGroup,
    ObjectRow,
    ArrowLeftIcon,
    ArrowRightIcon,
    DuplicateIcon,
  },
  data() {
    return {
      ceilingOptions: [
        { label: "Latten", value: "Latten" },
        { label: "Cassette", value: "Cassette" },
      ],
      koofOptions: [
        {
          label: "Met koof",
          value: "Met koof",
        },
        {
          label: "Zonder koof",
          value: "Zonder koof",
        },
      ],
      absorptionPanelsSizeOptions: [
        {
          label: "116x116 cm",
          value: "116x116",
        },
        {
          label: "120x120 cm",
          value: "120x120",
        },
        {
          label: "116x176 cm",
          value: "116x176",
        },
        {
          label: "120x180 cm",
          value: "120x180",
        },
        {
          label: "116x236 cm",
          value: "116x236",
        },
        {
          label: "120x240 cm",
          value: "120x240",
        },
      ],
    };
  },
  computed: {
    openAccordion: sync("openAccordion"),
    projectName: sync("projectName"),
    clientName: sync("clientName"),
    salesOwner: sync("salesOwner"),
    projectDate: sync("projectDate"),
    projectDescription: sync("projectDescription"),
    roomLength: sync("roomLength"),
    roomWidth: sync("roomWidth"),
    roomHeight: sync("roomHeight"),
    gapFront: sync("gapFront"),
    gapRight: sync("gapRight"),
    gapLeft: sync("gapLeft"),
    gapBack: sync("gapBack"),
    panelWidth: sync("panelWidth"),
    panelHeight: sync("panelHeight"),
    defaultPanelWidth: sync("defaultPanelWidth"),
    sweetspotPosition: sync("sweetspotPosition"),
    ceilingType: sync("ceilingType"),
    koof: sync("koof"),
    ceilingWidth: sync("ceilingWidth"),
    ceilingLength: sync("ceilingLength"),
    ceilingDistanceToFront: sync("ceilingDistanceToFront"),
    absorptionPanelsSize: sync("absorptionPanelsSize"),
    absorptionPanelsRows: sync("absorptionPanelsRows"),
    absorptionPanelsColumns: sync("absorptionPanelsColumns"),
    absorptionPanelsDistanceBetween: sync("absorptionPanelsDistanceBetween"),
    absorptionPanelsOrientation: sync("absorptionPanelsOrientation"),
    cassetteWidth: sync("cassetteWidth"),
    cassetteLength: sync("cassetteLength"),
    cassetteRows: sync("cassetteRows"),
    cassetteColumns: sync("cassetteColumns"),
    cassetteGapY: sync("cassetteGapY"),
    cassetteGapX: sync("cassetteGapX"),
    podesten: sync("podesten"),
    walls: get("walls"),
    preferredWall: sync("preferredWall"),
    totalObjectSize: get("totalObjectSize"),
    panelSpace: get("panelSpace"),
    curveDiffusersVoorwand: sync("curveDiffusersVoorwand"),
    curveDiffusersAchterwand: sync("curveDiffusersAchterwand"),
    showCreatePanelModal: sync("showCreatePanelModal"),
    showEditPanelModal: sync("showEditPanelModal"),
    objectToEdit: sync("objectToEdit"),
    version: sync("version"),
    voorwandCurveDiffuserVlakkeAfwerking: sync(
      "voorwandCurveDiffuserVlakkeAfwerking"
    ),
    achterwandCurveDiffuserVlakkeAfwerking: sync(
      "achterwandCurveDiffuserVlakkeAfwerking"
    ),
  },
  watch: {
    curveDiffusersVoorwand(value) {
      if (value === -1 || value === 5.5) {
        this.voorwandCurveDiffuserVlakkeAfwerking = false;
      }
    },
    curveDiffusersAchterwand(value) {
      if (value === -1 || value === 5.5) {
        this.achterwandCurveDiffuserVlakkeAfwerking = false;
      }
    },
  },
  methods: {
    setAccordion(label) {
      if (this.openAccordion === label) {
        this.openAccordion = null;
      } else {
        this.openAccordion = label;
      }
    },
    editObject(wallIndex, objectIndex) {
      this.objectToEdit = {
        objectIndex,
        wallIndex,
        ...this.walls[wallIndex].objects[objectIndex],
      };
      this.showEditPanelModal = true;
    },
    duplicateObject(wallIndex, objectIndex) {
      this.$store.commit("duplicateObject", { wallIndex, objectIndex });
    },
    deleteObject(objectIndex, wall) {
      if (window.confirm("Weet je zeker dat je dit object wilt verwijderen?")) {
        this.$store.commit("deleteObject", {
          objectIndex,
          wallName: wall.name,
        });
      }
    },
    duplicateWall(direction) {
      const confirmText =
        direction === "rightToLeft"
          ? "Weet je zeker dat je zijwand rechts naar zijwand links wilt dupliceren?"
          : "Weet je zeker dat je zijwand links naar zijwand rechts wilt dupliceren?";
      if (window.confirm(confirmText)) {
        this.$store.commit("duplicateWall", direction);
      }
    },
    addPodest() {
      this.podesten.push({
        name: `Podest ${this.podesten.length + 1}`,
        width: 120,
      });
    },
    deletePodest(index) {
      this.podesten.splice(index, 1);
    },
  },
};
</script>
