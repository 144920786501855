var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"inline-flex items-center cursor-pointer select-none border border-gray-700 bg-white/5 rounded-full relative group",attrs:{"for":_vm.name}},[_c('div',{staticClass:"relative inline-flex items-center flex-shrink-0 transition-colors duration-200 ease-in-out border-r border-gray-600 rounded-full focus:outline-none",class:{
      'bg-primary-600 border-primary-700': _vm.value === true,
      'bg-gray-700': _vm.value === !true,
      'h-7 w-10': _vm.size !== 'small',
      'h-5 w-8': _vm.size === 'small',
    }},[_c('span',{staticClass:"inline-block transition duration-200 ease-in-out transform bg-white border rounded-full shadow pointer-events-none",class:{
        'translate-x-5': _vm.value === true && _vm.size !== 'small',
        'translate-x-4': _vm.value === true && _vm.size === 'small',
        'translate-x-1': _vm.value === !true,
        'w-4 h-4': _vm.size !== 'small',
        'w-2.5 h-2.5': _vm.size === 'small',
      }})]),_c('input',{staticClass:"absolute invisible pointer-events-none",attrs:{"type":"checkbox","id":_vm.name},domProps:{"checked":_vm.value},on:{"change":function($event){return _vm.$emit('input', $event.target.checked)}}}),_c('span',{staticClass:"ml-2 pr-2.5 font-semibold text-gray-300",class:{
      'text-sm py-1': _vm.size !== 'small',
      'text-xs': _vm.size === 'small',
    }},[_vm._v(_vm._s(_vm.label))]),(_vm.tooltip)?_c('div',{staticClass:"absolute bottom-full mb-1.5 right-0 bg-gray-800 font-semibold px-1 rounded text-xs py-0.5 text-gray-200 tabular-nums border border-gray-700 text-center opacity-0 transition-opacity group-hover:opacity-100 pointer-events-none w-48"},[_vm._v(" "+_vm._s(_vm.tooltip)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }