var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.walls),function(wall,wallIndex){return _c('div',{key:wallIndex,staticClass:"absolute flex pointer-events-none",class:{
      'left-0 flex-col': wallIndex === 0,
      'top-0': wallIndex === 1,
      'bottom-0 items-end': wallIndex === 2,
      'right-0 flex-col items-end': wallIndex === 3,
    },style:(_vm.getStyleObject(wallIndex))},[(_vm.curveDiffusersVoorwand !== -1 && wallIndex !== 3)?_c('wall-panel',{attrs:{"object-data":{
        hovered: false,
        id: 0,
        type: _vm.voorwandCurveDiffuserVlakkeAfwerking
          ? 'Absorber'
          : _vm.curveDiffusersVoorwand === 5.5
          ? 'Schaduwblok'
          : 'CurveDiffuser',
        size: _vm.curveDiffusersVoorwand,
        thickness: 5.5,
        isCornerElement: true,
      },"orientation":wallIndex === 0 || wallIndex === 3 ? 'vertical' : 'horizontal',"wall-index":wallIndex}}):_vm._e(),(_vm.curveDiffusersAchterwand !== -1 && wallIndex === 3)?_c('wall-panel',{attrs:{"object-data":{
        hovered: false,
        id: 0,
        type: _vm.achterwandCurveDiffuserVlakkeAfwerking
          ? 'Absorber'
          : _vm.curveDiffusersAchterwand === 5.5
          ? 'Schaduwblok'
          : 'CurveDiffuser',
        size: _vm.curveDiffusersAchterwand,
        thickness: 5.5,
        isCornerElement: true,
      },"orientation":wallIndex === 0 || wallIndex === 3 ? 'vertical' : 'horizontal',"wall-index":wallIndex}}):_vm._e(),_vm._l((wall.objects),function(object,objectIndex){return _c('wall-panel',{key:object.id,attrs:{"object-data":object,"orientation":wallIndex === 0 || wallIndex === 3 ? 'vertical' : 'horizontal',"wall-index":wallIndex,"object-index":objectIndex}})}),(_vm.curveDiffusersAchterwand !== -1 && wallIndex !== 0)?_c('wall-panel',{style:({
        margin:
          wallIndex === 0 || wallIndex === 3 ? 'auto 0 0 0' : '0 0 0 auto',
      }),attrs:{"object-data":{
        hovered: false,
        id: 0,
        type: _vm.achterwandCurveDiffuserVlakkeAfwerking
          ? 'Absorber'
          : _vm.curveDiffusersAchterwand === 5.5
          ? 'Schaduwblok'
          : 'CurveDiffuser',
        size: _vm.curveDiffusersAchterwand,
        thickness: 5.5,
        isCornerElement: true,
      },"orientation":wallIndex === 0 || wallIndex === 3 ? 'vertical' : 'horizontal',"wall-index":wallIndex}}):_vm._e(),(_vm.curveDiffusersVoorwand !== -1 && wallIndex === 0)?_c('wall-panel',{style:({
        margin:
          wallIndex === 0 || wallIndex === 3 ? 'auto 0 0 0' : '0 0 0 auto',
      }),attrs:{"object-data":{
        hovered: false,
        id: 0,
        type: _vm.voorwandCurveDiffuserVlakkeAfwerking
          ? 'Absorber'
          : _vm.curveDiffusersVoorwand === 5.5
          ? 'Schaduwblok'
          : 'CurveDiffuser',
        size: _vm.curveDiffusersVoorwand,
        thickness: 5.5,
        isCornerElement: true,
      },"orientation":wallIndex === 0 || wallIndex === 3 ? 'vertical' : 'horizontal',"wall-index":wallIndex}}):_vm._e()],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }