var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"max-w-7xl"},[_c('summary-data',{attrs:{"data":_vm.projectData}}),_c('summary-data',{attrs:{"data":_vm.roomData}}),_c('summary-data',{attrs:{"data":_vm.absorbtionReflectionAndDiffusionData}}),_c('summary-data',{attrs:{"data":_vm.ceilingData}})],1),_c('div',{staticClass:"p-4 mt-8 max-w-7xl text-black"},[_c('table',{staticClass:"w-full"},[_vm._m(1),_vm._l((_vm.walls),function(wall,wallIndex){return _c('tbody',{key:wallIndex,staticClass:"text-sm font-semibold text-gray-800"},[(_vm.curveDiffusersVoorwand !== -1 && wallIndex === 0)?_c('tr',[_c('td',[_vm._v("HV")]),_c('td',[_vm._v(" "+_vm._s(_vm.voorwandCurveDiffuserVlakkeAfwerking ? "Hoekelement" : _vm.curveDiffusersVoorwand === 5.5 ? "Schaduwblok" : "CurveDiffuser")+" ")]),_c('td',[_vm._v(_vm._s(_vm.panelHeight))]),_c('td',[_vm._v(_vm._s(Math.round(_vm.curveDiffusersVoorwand / 0.7 / 10) * 10))])]):_vm._e(),(_vm.curveDiffusersAchterwand !== -1 && wallIndex === 3)?_c('tr',[_c('td',[_vm._v("HA")]),_c('td',[_vm._v(" "+_vm._s(_vm.achterwandCurveDiffuserVlakkeAfwerking ? "Hoekelement" : _vm.curveDiffusersAchterwand === 5.5 ? "Schaduwblok" : "CurveDiffuser")+" ")]),_c('td',[_vm._v(_vm._s(_vm.panelHeight))]),_c('td',[_vm._v(_vm._s(Math.round(_vm.curveDiffusersAchterwand / 0.7 / 10) * 10))])]):_vm._e(),_vm._l((wall.objects),function(object,objectIndex){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(
            object.type !== 'Schaduwlat' &&
              object.otherType.toLowerCase().includes('schaduw') === false
          ),expression:"\n            object.type !== 'Schaduwlat' &&\n              object.otherType.toLowerCase().includes('schaduw') === false\n          "}],key:objectIndex},[_c('td',[_vm._v(_vm._s(_vm.getElementId(wallIndex, objectIndex)))]),_c('td',[_vm._v(" "+_vm._s(object.type === "Anders" ? object.otherType : object.type)+" ")]),_c('td',[_vm._v(_vm._s(object.height))]),_c('td',[(object.size !== 'auto')?_c('span',[_vm._v(" "+_vm._s(object.size === "custom" ? object.otherSize.toString().replace(".", ",") : object.size.toString().replace(".", ","))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.autoSizedObjectsSize(wallIndex) .toString() .replace(".", ","))+" ")])]),_c('td',[_vm._v(_vm._s(object.thickness.toString().replace(".", ",")))])])}),(_vm.curveDiffusersVoorwand !== -1 && wallIndex === 0)?_c('tr',[_c('td',[_vm._v("HV")]),_c('td',[_vm._v(" "+_vm._s(_vm.voorwandCurveDiffuserVlakkeAfwerking ? "Hoekelement" : _vm.curveDiffusersVoorwand === 5.5 ? "Schaduwblok" : "CurveDiffuser")+" ")]),_c('td',[_vm._v(_vm._s(_vm.panelHeight))]),_c('td',[_vm._v(_vm._s(Math.round(_vm.curveDiffusersVoorwand / 0.7 / 10) * 10))])]):_vm._e(),(_vm.curveDiffusersAchterwand !== -1 && wallIndex === 3)?_c('tr',[_c('td',[_vm._v("HA")]),_c('td',[_vm._v(" "+_vm._s(_vm.achterwandCurveDiffuserVlakkeAfwerking ? "Hoekelement" : _vm.curveDiffusersAchterwand === 5.5 ? "Schaduwblok" : "CurveDiffuser")+" ")]),_c('td',[_vm._v(_vm._s(_vm.panelHeight))]),_c('td',[_vm._v(_vm._s(Math.round(_vm.curveDiffusersAchterwand / 0.7 / 10) * 10))])]):_vm._e(),_vm._m(2,true)],2)})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-between pt-8 pb-2 px-4"},[_c('div',{staticClass:"flex items-center"},[_c('img',{staticClass:"w-40",attrs:{"src":"/img/Rivasono-logo-met-slogan.svg","alt":"Rivasono Logo"}}),_c('div',{staticClass:"ml-6 font-semibold text-gray-800"},[_vm._v("Esperio Planner")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"text-sm font-semibold text-gray-600"},[_c('td',[_vm._v("Code")]),_c('td',[_vm._v("Object")]),_c('td',[_vm._v("Hoogte")]),_c('td',[_vm._v("Breedte")]),_c('td',[_vm._v("Dikte")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"py-2"})])
}]

export { render, staticRenderFns }