<template>
  <div class="border-b border-gray-600">
    <button
      class="flex items-center w-full p-4 transition focus:outline-none"
      @click="$emit('toggle', label)"
    >
      <div class="text-sm font-semibold">{{ label }}</div>
      <div class="ml-auto"><slot name="head" /></div>
      <div class="ml-2">
        <chevron-down-icon class="w-5 h-5" v-show="openStatus !== label" />
        <chevron-up-icon class="w-5 h-5" v-show="openStatus === label" />
      </div>
    </button>
    <div class="px-4 pt-2 pb-5" v-show="openStatus === label">
      <slot />
    </div>
  </div>
</template>

<script>
import ChevronDownIcon from "../icons/ChevronDownIcon";
import ChevronUpIcon from "../icons/ChevronUpIcon";

export default {
  name: "AccordionWrapper",
  props: ["label", "openStatus"],
  components: {
    ChevronDownIcon,
    ChevronUpIcon
  }
};
</script>
