<template>
  <div>
    <div
      v-for="(wall, wallIndex) in walls"
      :key="wallIndex"
      class="absolute flex pointer-events-none"
      :class="{
        'left-0 flex-col': wallIndex === 0,
        'top-0': wallIndex === 1,
        'bottom-0 items-end': wallIndex === 2,
        'right-0 flex-col items-end': wallIndex === 3,
      }"
      :style="getStyleObject(wallIndex)"
    >
      <wall-panel
        v-if="curveDiffusersVoorwand !== -1 && wallIndex !== 3"
        :object-data="{
          hovered: false,
          id: 0,
          type: voorwandCurveDiffuserVlakkeAfwerking
            ? 'Absorber'
            : curveDiffusersVoorwand === 5.5
            ? 'Schaduwblok'
            : 'CurveDiffuser',
          size: curveDiffusersVoorwand,
          thickness: 5.5,
          isCornerElement: true,
        }"
        :orientation="
          wallIndex === 0 || wallIndex === 3 ? 'vertical' : 'horizontal'
        "
        :wall-index="wallIndex"
      />
      <wall-panel
        v-if="curveDiffusersAchterwand !== -1 && wallIndex === 3"
        :object-data="{
          hovered: false,
          id: 0,
          type: achterwandCurveDiffuserVlakkeAfwerking
            ? 'Absorber'
            : curveDiffusersAchterwand === 5.5
            ? 'Schaduwblok'
            : 'CurveDiffuser',
          size: curveDiffusersAchterwand,
          thickness: 5.5,
          isCornerElement: true,
        }"
        :orientation="
          wallIndex === 0 || wallIndex === 3 ? 'vertical' : 'horizontal'
        "
        :wall-index="wallIndex"
      />
      <wall-panel
        v-for="(object, objectIndex) in wall.objects"
        :object-data="object"
        :key="object.id"
        :orientation="
          wallIndex === 0 || wallIndex === 3 ? 'vertical' : 'horizontal'
        "
        :wall-index="wallIndex"
        :object-index="objectIndex"
      />
      <wall-panel
        :style="{
          margin:
            wallIndex === 0 || wallIndex === 3 ? 'auto 0 0 0' : '0 0 0 auto',
        }"
        v-if="curveDiffusersAchterwand !== -1 && wallIndex !== 0"
        :object-data="{
          hovered: false,
          id: 0,
          type: achterwandCurveDiffuserVlakkeAfwerking
            ? 'Absorber'
            : curveDiffusersAchterwand === 5.5
            ? 'Schaduwblok'
            : 'CurveDiffuser',
          size: curveDiffusersAchterwand,
          thickness: 5.5,
          isCornerElement: true,
        }"
        :orientation="
          wallIndex === 0 || wallIndex === 3 ? 'vertical' : 'horizontal'
        "
        :wall-index="wallIndex"
      />
      <wall-panel
        :style="{
          margin:
            wallIndex === 0 || wallIndex === 3 ? 'auto 0 0 0' : '0 0 0 auto',
        }"
        v-if="curveDiffusersVoorwand !== -1 && wallIndex === 0"
        :object-data="{
          hovered: false,
          id: 0,
          type: voorwandCurveDiffuserVlakkeAfwerking
            ? 'Absorber'
            : curveDiffusersVoorwand === 5.5
            ? 'Schaduwblok'
            : 'CurveDiffuser',
          size: curveDiffusersVoorwand,
          thickness: 5.5,
          isCornerElement: true,
        }"
        :orientation="
          wallIndex === 0 || wallIndex === 3 ? 'vertical' : 'horizontal'
        "
        :wall-index="wallIndex"
      />
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

import WallPanel from "./WallPanel";

export default {
  name: "WallPanels",
  components: {
    WallPanel,
  },
  computed: {
    curveDiffusersVoorwand: get("curveDiffusersVoorwand"),
    curveDiffusersAchterwand: get("curveDiffusersAchterwand"),
    walls: get("walls"),
    panelSpace: get("panelSpace"),
    panelWidth: get("panelWidth"),
    voorwandCurveDiffuserVlakkeAfwerking: get(
      "voorwandCurveDiffuserVlakkeAfwerking"
    ),
    achterwandCurveDiffuserVlakkeAfwerking: get(
      "achterwandCurveDiffuserVlakkeAfwerking"
    ),
  },
  methods: {
    getStyleObject(wallIndex) {
      if (wallIndex === 0 || wallIndex === 3) {
        return {
          width: "100%",
          height: "100%",
          top: 0,
          bottom: 0,
        };
      } else if (wallIndex === 1 || wallIndex === 2) {
        return {
          width: "100%",
          height: "100%",
          top: 0,
          bottom: 0,
        };
      }
    },
  },
};
</script>
