<template>
  <div
    class="w-full transition bg-gray-700 border-t border-b border-l-4 border-r border-gray-600 rounded shadow cursor-move"
    @click="$emit('edit')"
    :class="{
      'ring-2 ring-primary-500 border-primary-500': objectData.hovered,
      'border-l-teal-500': objectData.color
        ? objectData.color === 'teal'
        : objectData.type === 'Absorber',
      'border-l-purple-500': objectData.color
        ? objectData.color === 'purple'
        : objectData.type === 'CurveDiffuser',
      'border-l-blue-500': objectData.color
        ? objectData.color === 'blue'
        : objectData.type === 'Flatdiffuser',
      'border-l-lime-500': objectData.color
        ? objectData.color === 'lime'
        : objectData.type === 'v10 panel',
      'border-l-red-500': objectData.color
        ? objectData.color === 'red'
        : objectData.type === 'Reflector',
      'border-l-fuchsia-500': objectData.color
        ? objectData.color === 'fuchsia'
        : objectData.type === 'Visual',
      'border-l-gray-400': objectData.color
        ? objectData.color === 'gray'
        : objectData.type === 'Leeg',
      'border-l-yellow-500': objectData.color
        ? objectData.color === 'yellow'
        : objectData.type === 'Anders',
    }"
  >
    <div class="flex items-start space-x-2 px-2 py-2">
      <div
        class="text-xs font-bold text-gray-300 bg-gray-600 px-1 py-0.5 rounded tabular-nums"
      >
        <div v-if="wallIndex === 0">V{{ objectIndex + 1 }}</div>
        <div v-if="wallIndex === 1">R{{ objectIndex + 1 }}</div>
        <div v-if="wallIndex === 2">L{{ objectIndex + 1 }}</div>
        <div v-if="wallIndex === 3">A{{ objectIndex + 1 }}</div>
      </div>
      <div class="flex-1">
        <div class="text-sm font-semibold text-white">
          {{
            objectData.type === "Anders"
              ? objectData.otherType
              : objectData.type
          }}
        </div>
        <div class="mt-1">
          <div
            class="text-sm font-semibold text-gray-300 tabular-nums"
            v-if="objectData.size !== 'auto'"
          >
            {{
              objectData.size === "custom"
                ? objectData.otherSize
                : objectData.size
            }}
            cm
          </div>
          <div
            class="flex items-center text-sm font-semibold text-primary-400 tabular-nums space-x-1"
            v-else
          >
            <bolt-icon />
            <div>{{ autoSizedObjectsSize(wallIndex) }} cm</div>
          </div>
        </div>
      </div>
      <div class="flex">
        <button
          class="p-1 ml-2 text-gray-300 transition rounded hover:bg-gray-600 focus:outline-none"
          @click.stop="$emit('duplicate')"
          v-tooltip="'Dupliceer'"
        >
          <duplicate-icon class="w-5 h-5" />
        </button>
        <button
          class="p-1 text-gray-300 transition rounded hover:bg-gray-600 focus:outline-none"
          @click.stop="$emit('edit')"
          v-tooltip="'Bewerk'"
        >
          <pencil-icon class="w-5 h-5" />
        </button>
        <button
          class="p-1 text-gray-300 transition rounded hover:bg-gray-600 focus:outline-none"
          @click.stop="$emit('delete')"
          v-tooltip="'Verwijder'"
        >
          <trash-icon class="w-5 h-5" />
        </button>
      </div>
    </div>
    <div
      v-if="objectData.note"
      class="text-xs font-semibold text-gray-300 bg-black/10 px-3 py-2 border-t border-gray-600"
    >
      {{ objectData.note }}
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

import BoltIcon from "./icons/BoltIcon";
import PencilIcon from "./icons/PencilIcon";
import DuplicateIcon from "./icons/DuplicateIcon";
import TrashIcon from "./icons/TrashIcon";

export default {
  name: "ObjectRow",
  components: {
    BoltIcon,
    PencilIcon,
    DuplicateIcon,
    TrashIcon,
  },
  computed: {
    autoSizedObjectsSize: get("autoSizedObjectsSize"),
  },
  props: ["objectData", "objectIndex", "wallIndex"],
};
</script>
