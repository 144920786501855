import Vue from "vue";
import Vuex from "vuex";
import { make } from "vuex-pathify";

import pathify from "./pathify";

import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const state = {
  projectName: "",
  clientName: "",
  salesOwner: "Frank Imholz",
  projectDate: "",
  projectDescription: "",
  roomLength: 800,
  roomWidth: 450,
  roomHeight: 270,
  gapFront: 12,
  gapRight: 8,
  gapLeft: 8,
  gapBack: 12,
  panelWidth: 5.5,
  panelHeight: 180,
  defaultPanelWidth: 3,
  sweetspotPosition: 38,
  allLabelsShown: false,
  ceilingShown: false,
  ceilingType: "Absorptiepanelen",
  koof: "Met koof",
  ceilingWidth: 360,
  ceilingLength: 550,
  ceilingDistanceToFront: 120,
  absorptionPanelsSize: "120x120",
  absorptionPanelsRows: "2",
  absorptionPanelsColumns: "3",
  absorptionPanelsOrientation: "In de lengte",
  absorptionPanelsDistanceBetween: 4,
  cassetteWidth: 40,
  cassetteLength: 40,
  cassetteRows: 2,
  cassetteColumns: 3,
  cassetteGapY: 4,
  cassetteGapX: 4,
  curveDiffusersVoorwand: -1,
  voorwandCurveDiffuserVlakkeAfwerking: false,
  curveDiffusersAchterwand: -1,
  achterwandCurveDiffuserVlakkeAfwerking: false,
  showCreatePanelModal: false,
  showEditPanelModal: false,
  objectToEdit: null,
  openAccordion: "",
  surroundSound: "Geen",
  preferredWall: null,
  importPromptShown: false,
  exportPromptShown: false,
  podesten: [],
  version: "1.0",
  walls: [
    {
      name: "Voorwand",
      objects: [],
    },
    {
      name: "Zijwand rechts",
      objects: [],
    },
    {
      name: "Zijwand links",
      objects: [],
    },
    {
      name: "Achterwand",
      objects: [],
    },
  ],
};

const getters = {
  ...make.getters(state),
  roomRatio: state => {
    return state.roomWidth / state.roomLength;
  },
  insideLength: state => {
    return state.roomLength - state.gapFront - state.gapBack;
  },
  panelSpace: state => orientation => {
    let panelSpace = 0;
    if (orientation === "vertical") {
      panelSpace += state.roomWidth - (state.gapLeft + state.gapRight);
    } else if (orientation === "horizontal") {
      panelSpace += state.roomLength - (state.gapFront + state.gapBack);
    }
    return panelSpace;
  },
  insideWidth: state => {
    return state.roomWidth - state.gapLeft - state.gapRight;
  },
  amountOfAutoSizedObjects: state => wallName => {
    if (wallName) {
      return state.walls
        .find(wall => wall.name === wallName)
        .objects.reduce((accumulator, object) => {
          if (object.size === "auto") {
            return accumulator + 1;
          } else {
            return accumulator;
          }
        }, 0);
    } else {
      return 0;
    }
  },
  availableSpace: (state, getters) => wallIndex => {
    let totalFixedObjectSize = 0;
    if (wallIndex === "Voorwand") {
      wallIndex = 0;
    } else if (wallIndex === "Zijwand rechts") {
      wallIndex = 1;
    } else if (wallIndex === "Zijwand links") {
      wallIndex = 2;
    } else if (wallIndex === "Achterwand") {
      wallIndex = 3;
    }
    state.walls[wallIndex].objects.forEach(object => {
      if (isNaN(object.size) === false) {
        totalFixedObjectSize += object.size;
      } else if (object.size === "custom") {
        totalFixedObjectSize += object.otherSize;
      }
    });
    if (wallIndex === 0) {
      if (state.curveDiffusersVoorwand !== -1) {
        totalFixedObjectSize += state.curveDiffusersVoorwand * 2;
      }
    } else if (wallIndex === 1 || wallIndex === 2) {
      if (state.curveDiffusersVoorwand !== -1) {
        totalFixedObjectSize += state.curveDiffusersVoorwand;
      }
      if (state.curveDiffusersAchterwand !== -1) {
        totalFixedObjectSize += state.curveDiffusersAchterwand;
      }
    } else if (wallIndex === 3) {
      if (state.curveDiffusersAchterwand !== -1) {
        totalFixedObjectSize += state.curveDiffusersAchterwand * 2;
      }
    }
    if (wallIndex === 0 || wallIndex === 3) {
      return getters.panelSpace("vertical") - totalFixedObjectSize;
    } else {
      return getters.panelSpace("horizontal") - totalFixedObjectSize;
    }
  },
  autoSizedObjectsSize: (state, getters) => wallIndex => {
    let totalFixedObjectSize = 0;
    let amountOfAutoSizedObjects = 0;
    if (wallIndex === "Voorwand") {
      wallIndex = 0;
    } else if (wallIndex === "Zijwand rechts") {
      wallIndex = 1;
    } else if (wallIndex === "Zijwand links") {
      wallIndex = 2;
    } else if (wallIndex === "Achterwand") {
      wallIndex = 3;
    }
    state.walls[wallIndex].objects.forEach(object => {
      if (isNaN(object.size) === false) {
        totalFixedObjectSize += object.size;
      } else if (object.size === "custom") {
        totalFixedObjectSize += object.otherSize;
      } else {
        amountOfAutoSizedObjects += 1;
      }
    });
    if (wallIndex === 0) {
      if (state.curveDiffusersVoorwand !== -1) {
        totalFixedObjectSize += state.curveDiffusersVoorwand * 2;
      }
    } else if (wallIndex === 1 || wallIndex === 2) {
      if (state.curveDiffusersVoorwand !== -1) {
        totalFixedObjectSize += state.curveDiffusersVoorwand;
      }
      if (state.curveDiffusersAchterwand !== -1) {
        totalFixedObjectSize += state.curveDiffusersAchterwand;
      }
    } else if (wallIndex === 3) {
      if (state.curveDiffusersAchterwand !== -1) {
        totalFixedObjectSize += state.curveDiffusersAchterwand * 2;
      }
    }
    if (amountOfAutoSizedObjects === 0) {
      return 0;
    } else {
      if (wallIndex === 0 || wallIndex === 3) {
        let autoSizedObjectsSize =
          (getters.panelSpace("vertical") - totalFixedObjectSize) /
          amountOfAutoSizedObjects;
        if (autoSizedObjectsSize > 0) {
          return Math.round(autoSizedObjectsSize * 100) / 100;
        } else {
          return 0;
        }
      } else {
        let autoSizedObjectsSize =
          (getters.panelSpace("horizontal") - totalFixedObjectSize) /
          amountOfAutoSizedObjects;
        if (autoSizedObjectsSize > 0) {
          return Math.round(autoSizedObjectsSize * 100) / 100;
        } else {
          return 0;
        }
      }
    }
  },
  totalObjectSize: (state, getters) => wallIndex => {
    let totalObjectSize = 0;
    if (wallIndex === 0) {
      if (state.curveDiffusersVoorwand !== -1) {
        totalObjectSize += state.curveDiffusersVoorwand * 2;
      }
    } else if (wallIndex === 1 || wallIndex === 2) {
      if (state.curveDiffusersVoorwand !== -1) {
        totalObjectSize += state.curveDiffusersVoorwand;
      }
      if (state.curveDiffusersAchterwand !== -1) {
        totalObjectSize += state.curveDiffusersAchterwand;
      }
    } else if (wallIndex === 3) {
      if (state.curveDiffusersAchterwand !== -1) {
        totalObjectSize += state.curveDiffusersAchterwand * 2;
      }
    }
    state.walls[wallIndex].objects.forEach(object => {
      if (object.size === "auto") {
        totalObjectSize += getters.autoSizedObjectsSize(wallIndex);
      } else if (object.size === "custom") {
        totalObjectSize += object.otherSize;
      } else {
        totalObjectSize += object.size;
      }
    });
    return Math.round(totalObjectSize * 10) / 10;
  },
  totalAbsorbtion: (state, getters) => {
    let totalAbsorbtion = 0;
    state.walls.forEach((wall, wallIndex) => {
      wall.objects.forEach(object => {
        if (
          object.type === "Absorber" ||
          object.otherType.toLowerCase().includes("absorber")
        ) {
          if (object.size === "custom") {
            totalAbsorbtion += (object.otherSize / 100) * (object.height / 100);
          } else if (object.size === "auto") {
            totalAbsorbtion +=
              (getters.autoSizedObjectsSize(wallIndex) / 100) *
              (object.height / 100);
          } else {
            totalAbsorbtion += (object.size / 100) * (object.height / 100);
          }
        }
      });
    });
    return Math.round(totalAbsorbtion * 100) / 100;
  },
  totalReflection: (state, getters) => {
    let totalReflection = 0;
    state.walls.forEach((wall, wallIndex) => {
      wall.objects.forEach(object => {
        if (
          object.type === "Reflector" ||
          object.otherType.toLowerCase().includes("reflector")
        ) {
          if (object.size === "custom") {
            totalReflection += (object.otherSize / 100) * (object.height / 100);
          } else if (object.size === "auto") {
            totalReflection +=
              (getters.autoSizedObjectsSize(wallIndex) / 100) *
              (object.height / 100);
          } else {
            totalReflection += (object.size / 100) * (object.height / 100);
          }
        }
      });
    });
    return Math.round(totalReflection * 100) / 100;
  },
  totalDiffusion: (state, getters) => {
    let totalDiffusion = 0;
    state.walls.forEach((wall, wallIndex) => {
      wall.objects.forEach(object => {
        if (
          object.type === "Flatdiffuser" ||
          object.type === "CurveDiffuser" ||
          object.otherType.toLowerCase().includes("diffuser")
        ) {
          if (object.size === "custom") {
            totalDiffusion += (object.otherSize / 100) * (object.height / 100);
          } else if (object.size === "auto") {
            totalDiffusion +=
              (getters.autoSizedObjectsSize(wallIndex) / 100) *
              (object.height / 100);
          } else {
            totalDiffusion += (object.size / 100) * (object.height / 100);
          }
        }
      });
    });
    return Math.round(totalDiffusion * 100) / 100;
  },
  lattenList: (state, getters) => {
    const availableLattenSizes = [5, 9, 12];
    const lattenArray = [];
    const lengthToFill = (getters.ceilingLength / 3) * 2;
    let totalLattenLength = 0;
    while (totalLattenLength < lengthToFill) {
      const randomLatSize =
        availableLattenSizes[
          Math.floor(Math.random() * availableLattenSizes.length)
        ];
      lattenArray.push(randomLatSize);
      totalLattenLength += randomLatSize;
    }
    return lattenArray;
  },
};

const mutations = {
  ...make.mutations(state),
  addObjectToWall(state, { object, wallName }) {
    const wallToAddObjectTo = state.walls.find(wall => {
      return wall.name === wallName;
    });
    Vue.set(
      wallToAddObjectTo.objects,
      wallToAddObjectTo.objects.length,
      object
    );
  },
  editObject(state, { object, wallName, objectIndex, bulkEditOptions }) {
    const wallWhereObjectIs = state.walls.find(wall => {
      return wall.name === wallName;
    });
    Vue.set(wallWhereObjectIs.objects, objectIndex, {
      ...wallWhereObjectIs.objects[objectIndex],
      ...object,
    });
    if (
      bulkEditOptions.width ||
      bulkEditOptions.depth ||
      bulkEditOptions.height
    ) {
      const indexesOfElementsWithSameType = [];
      wallWhereObjectIs.objects.forEach((object, index) => {
        if (object.type === wallWhereObjectIs.objects[objectIndex].type) {
          indexesOfElementsWithSameType.push(index);
        }
      });
      indexesOfElementsWithSameType.forEach(index => {
        if (bulkEditOptions.width) {
          if (object.size === "custom") {
            Vue.set(wallWhereObjectIs.objects, index, {
              ...wallWhereObjectIs.objects[index],
              size: "custom",
              otherSize: object.otherSize,
            });
          } else {
            Vue.set(wallWhereObjectIs.objects, index, {
              ...wallWhereObjectIs.objects[index],
              size: object.size,
              otherSize: "",
            });
          }
        }
        if (bulkEditOptions.depth) {
          Vue.set(wallWhereObjectIs.objects, index, {
            ...wallWhereObjectIs.objects[index],
            thickness: object.thickness,
          });
        }
        if (bulkEditOptions.height) {
          Vue.set(wallWhereObjectIs.objects, index, {
            ...wallWhereObjectIs.objects[index],
            height: object.height,
          });
        }
      });
    }
  },
  deleteObject(state, { objectIndex, wallName }) {
    const wallWhereObjectIs = state.walls.find(wall => {
      return wall.name === wallName;
    });
    Vue.delete(wallWhereObjectIs.objects, objectIndex);
  },
  duplicateObject(state, { wallIndex, objectIndex }) {
    const wallObjects = state.walls[wallIndex].objects;
    const objectToDuplicate = wallObjects[objectIndex];
    wallObjects.splice(objectIndex, 0, {
      ...objectToDuplicate,
      id: Math.floor(Math.random() * 1000000),
    });
  },
  duplicateWall(state, direction) {
    if (direction === "rightToLeft") {
      state.walls[2].objects = JSON.parse(
        JSON.stringify(state.walls[1].objects)
      );
    } else if (direction === "leftToRight") {
      state.walls[1].objects = JSON.parse(
        JSON.stringify(state.walls[2].objects)
      );
    }
  },
  hoverObject(state, payload) {
    payload.objectData.hovered = payload.bool;
  },
};

const actions = {};

const store = {
  state,
  getters,
  mutations,
  actions,
  plugins: [pathify.plugin, new VuexPersistence().plugin],
};

export default new Vuex.Store(store);
