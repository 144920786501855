<template>
  <button
    class="flex items-center text-sm font-semibold leading-4 text-white transition focus:outline-none"
    :class="{
      'rounded px-3 py-2': rounded !== true,
      'rounded-full w-8 h-8': rounded === true,
      'bg-primary-600 border-b-2 border-primary-800': transparent !== true,
      'hover:bg-gray-700': transparent === true,
    }"
    @click="$emit('click')"
  >
    <component class="w-5 h-5" :is="icon" v-if="icon" />
    <div :class="{ 'ml-2': icon }" v-if="iconOnly !== true">
      <slot />
    </div>
  </button>
</template>

<script>
import PlusIcon from "../icons/PlusIcon";
import PrinterIcon from "../icons/PrinterIcon";
import PencilIcon from "../icons/PencilIcon";
import TrashIcon from "../icons/TrashIcon";
import CopyIcon from "../icons/CopyIcon";
import UploadIcon from "../icons/UploadIcon";
import DownloadIcon from "../icons/DownloadIcon";

export default {
  name: "BaseButton",
  components: {
    PlusIcon,
    PrinterIcon,
    PencilIcon,
    TrashIcon,
    CopyIcon,
    UploadIcon,
    DownloadIcon,
  },
  props: {
    icon: {
      default: false,
    },
    rounded: {
      default: false,
    },
    iconOnly: {
      default: false,
    },
    transparent: {
      default: false,
    },
  },
};
</script>
