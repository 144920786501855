import Vue from "vue";
import App from "./App.vue";
import store from "./store";

Vue.config.productionTip = false;

import "./tailwind.css";

import vToolTip from "v-tooltip";
Vue.use(vToolTip, {
  defaultTemplate: `<div class="tooltip pointer-events-none" role="tooltip">
      <div class="tooltip-arrow"></div>
      <div class="tooltip-inner m-1 bg-gray-800 font-semibold px-1 rounded text-xs py-0.5 text-gray-200 tabular-nums border border-gray-700"></div>
    </div>`,
});

import vClickOutside from "v-click-outside";
Vue.use(vClickOutside);

new Vue({
  store,
  render: h => h(App),
}).$mount("#app");
