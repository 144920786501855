<template>
  <div class="w-full h-full pointer-events-none">
    <div class="w-full h-full" v-if="surroundSound === '5.1'">
      <div
        class="absolute top-1/2 translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
        :style="{
          width: `${(2000 / panelSpace('horizontal')) * 100}%`,
          height: `${(2000 / panelSpace('vertical')) * 100}%`,
          right: `${sweetspotPosition}%`,
        }"
      >
        <img src="/img/5.1-surround.png" />
      </div>
    </div>
    <div class="w-full h-full" v-if="surroundSound === '7.1'">
      <div
        class="absolute top-1/2 translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
        :style="{
          width: `${(2000 / panelSpace('horizontal')) * 100}%`,
          height: `${(2000 / panelSpace('vertical')) * 100}%`,
          right: `${sweetspotPosition}%`,
        }"
      >
        <img src="/img/7.1-surround.png" />
      </div>
    </div>
    <div class="w-full h-full" v-if="surroundSound === '9.1'">
      <div
        class="absolute top-1/2 translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
        :style="{
          width: `${(2000 / panelSpace('horizontal')) * 100}%`,
          height: `${(2000 / panelSpace('vertical')) * 100}%`,
          right: `${sweetspotPosition}%`,
        }"
      >
        <img src="/img/9.1-surround.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  name: "SurroundSoundIndicators",
  data() {
    return {
      fiveDotOneIndicators: [
        {
          deg: -115,
          width: 10,
        },
        {
          deg: -26,
          width: 8,
        },
        {
          deg: 0,
          width: 3,
        },
        {
          deg: 26,
          width: 8,
        },
        {
          deg: 115,
          width: 10,
        },
      ],
      sevenDotOneIndicators: [
        {
          deg: -142.5,
          width: 15,
        },
        {
          deg: -100,
          width: 20,
        },
        {
          deg: -26,
          width: 8,
        },
        {
          deg: 0,
          width: 3,
        },
        {
          deg: 26,
          width: 8,
        },
        {
          deg: 100,
          width: 20,
        },
        {
          deg: 142.5,
          width: 15,
        },
      ],
      nineDotOneIndicators: [
        {
          deg: -142.5,
          width: 15,
        },
        {
          deg: -100,
          width: 20,
        },
        {
          deg: -60,
          width: 20,
        },
        {
          deg: -26,
          width: 8,
        },
        {
          deg: 0,
          width: 3,
        },
        {
          deg: 26,
          width: 8,
        },
        {
          deg: 60,
          width: 20,
        },
        {
          deg: 100,
          width: 20,
        },
        {
          deg: 142.5,
          width: 15,
        },
      ],
    };
  },
  computed: {
    panelSpace: get("panelSpace"),
    surroundSound: get("surroundSound"),
    sweetspotPosition: get("sweetspotPosition"),
  },
};
</script>
