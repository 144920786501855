<template>
  <div class="flex flex-col">
    <div class="text-sm font-semibold text-gray-300">
      {{ label }}
    </div>
    <div class="relative mt-1">
      <select
        class="w-full py-1 pl-3 pr-6 text-sm font-semibold leading-6 text-white transition bg-gray-700 border border-gray-600 rounded appearance-none cursor-pointer focus:border-primary-600 ring-primary-600 focus:ring-1 focus:outline-none tabular-nums"
        :value="value"
        @input="
          type === 'number'
            ? $emit('input', Number($event.target.value))
            : $emit('input', $event.target.value)
        "
      >
        <option
          class="font-semibold"
          :value="option.value"
          v-for="option in options"
          :key="option.value"
          >{{ option.label }}</option
        >
      </select>
      <div
        class="absolute top-0 flex items-center h-full pointer-events-none right-1"
      >
        <chevron-down-icon class="w-5 h-5 text-gray-500" />
      </div>
    </div>
  </div>
</template>

<script>
import ChevronDownIcon from "./icons/ChevronDownIcon";

export default {
  name: "SelectGroup",
  props: ["label", "options", "type", "value"],
  components: {
    ChevronDownIcon,
  },
};
</script>
