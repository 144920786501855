<template>
  <header class="py-4 bg-gray-900 border-b border-gray-600">
    <div class="flex items-center justify-between px-6 space-x-8">
      <div class="flex items-center">
        <img
          class="w-32"
          src="/img/rivasono-logo-white.svg"
          alt="Rivasono Logo"
        />
        <div class="ml-4 font-semibold text-gray-300">Esperio Planner</div>
      </div>
      <div class="flex-1"></div>
      <checkbox
        v-model="allLabelsShown"
        label="Toon alle objectlabels"
        name="allLabelsShown"
      />
      <checkbox
        v-model="ceilingShown"
        label="Toon plafond"
        name="ceilingShown"
      />
      <select-group
        label="Surround sound indicators"
        :options="surroundSoundOptions"
        v-model="surroundSound"
      />
      <base-button @click="$emit('print')" icon="PrinterIcon"
        >Print</base-button
      >
      <div class="relative" v-click-outside="closeMenu">
        <button
          class="relative flex items-center justify-center w-10 h-10 text-white transition rounded-full hover:bg-gray-700"
          @click="menuOpen = !menuOpen"
        >
          <dots-icon class="w-6 h-6" />
        </button>
        <transition name="fade">
          <div
            class="absolute right-0 z-50 text-white bg-gray-700 border border-gray-600 divide-y divide-gray-600 rounded shadow-md top-full"
            v-show="menuOpen"
          >
            <button
              class="flex items-center w-full px-3 py-3 space-x-3 text-sm font-semibold text-left transition whitespace-nowrap hover:bg-gray-600"
              v-for="action in menuActions"
              :key="action.label"
              @click="action.action"
            >
              <component class="w-5 h-5" :is="action.icon" />
              <div>{{ action.label }}</div>
            </button>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script>
import { sync } from "vuex-pathify";

import DotsIcon from "../icons/DotsIcon";
import TrashIcon from "../icons/TrashIcon";
import ImportIcon from "../icons/ImportIcon";
import ExportIcon from "../icons/ExportIcon";

import BaseButton from "../elements/BaseButton";
import Checkbox from "../elements/Checkbox";
import SelectGroup from "../SelectGroup";

import { encode } from "js-base64";

export default {
  name: "AppHeader",
  components: {
    DotsIcon,
    TrashIcon,
    ImportIcon,
    ExportIcon,
    Checkbox,
    BaseButton,
    SelectGroup,
  },
  data() {
    return {
      surroundSoundOptions: [
        {
          label: "Geen",
          value: "Geen",
        },

        {
          label: "5.1",
          value: "5.1",
        },
        {
          label: "7.1",
          value: "7.1",
        },
        {
          label: "9.1",
          value: "9.1",
        },
      ],
      menuOpen: false,
      menuActions: [
        {
          label: "Importeer ontwerp",
          icon: ImportIcon,
          action: this.import,
        },
        {
          label: "Exporteer ontwerp",
          icon: ExportIcon,
          action: this.export,
        },
        {
          label: "Verwijder huidig ontwerp",
          icon: TrashIcon,
          action: this.clearStorage,
        },
      ],
    };
  },
  computed: {
    allLabelsShown: sync("allLabelsShown"),
    ceilingShown: sync("ceilingShown"),
    surroundSound: sync("surroundSound"),
    importPromptShown: sync("importPromptShown"),
    exportPromptShown: sync("exportPromptShown"),
  },
  methods: {
    closeMenu() {
      this.menuOpen = false;
    },
    import() {
      this.importPromptShown = true;
      this.menuOpen = false;
    },
    export() {
      const storage = window.localStorage.getItem("vuex");
      this.$emit("setBase64PlanToExport", encode(storage));
      this.exportPromptShown = true;
      this.menuOpen = false;
    },
    clearStorage() {
      this.menuOpen = false;
      if (
        window.confirm(
          "Weet je het zeker dat je het huidige ontwerp wil verwijderen?"
        )
      ) {
        window.localStorage.removeItem("vuex");
        location.reload();
      }
    },
  },
};
</script>
