var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full pointer-events-none"},[(_vm.surroundSound === '5.1')?_c('div',{staticClass:"w-full h-full"},[_c('div',{staticClass:"absolute top-1/2 translate-x-1/2 -translate-y-1/2 flex items-center justify-center",style:({
        width: `${(2000 / _vm.panelSpace('horizontal')) * 100}%`,
        height: `${(2000 / _vm.panelSpace('vertical')) * 100}%`,
        right: `${_vm.sweetspotPosition}%`,
      })},[_c('img',{attrs:{"src":"/img/5.1-surround.png"}})])]):_vm._e(),(_vm.surroundSound === '7.1')?_c('div',{staticClass:"w-full h-full"},[_c('div',{staticClass:"absolute top-1/2 translate-x-1/2 -translate-y-1/2 flex items-center justify-center",style:({
        width: `${(2000 / _vm.panelSpace('horizontal')) * 100}%`,
        height: `${(2000 / _vm.panelSpace('vertical')) * 100}%`,
        right: `${_vm.sweetspotPosition}%`,
      })},[_c('img',{attrs:{"src":"/img/7.1-surround.png"}})])]):_vm._e(),(_vm.surroundSound === '9.1')?_c('div',{staticClass:"w-full h-full"},[_c('div',{staticClass:"absolute top-1/2 translate-x-1/2 -translate-y-1/2 flex items-center justify-center",style:({
        width: `${(2000 / _vm.panelSpace('horizontal')) * 100}%`,
        height: `${(2000 / _vm.panelSpace('vertical')) * 100}%`,
        right: `${_vm.sweetspotPosition}%`,
      })},[_c('img',{attrs:{"src":"/img/9.1-surround.png"}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }