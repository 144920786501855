<template>
  <label
    class="inline-flex items-center cursor-pointer select-none border border-gray-700 bg-white/5 rounded-full relative group"
    :for="name"
  >
    <div
      class="relative inline-flex items-center flex-shrink-0  transition-colors duration-200 ease-in-out border-r border-gray-600 rounded-full focus:outline-none"
      :class="{
        'bg-primary-600 border-primary-700': value === true,
        'bg-gray-700': value === !true,
        'h-7 w-10': size !== 'small',
        'h-5 w-8': size === 'small',
      }"
    >
      <span
        class="inline-block transition duration-200 ease-in-out transform bg-white border rounded-full shadow pointer-events-none"
        :class="{
          'translate-x-5': value === true && size !== 'small',
          'translate-x-4': value === true && size === 'small',
          'translate-x-1': value === !true,
          'w-4 h-4': size !== 'small',
          'w-2.5 h-2.5': size === 'small',
        }"
      ></span>
    </div>
    <input
      class="absolute invisible pointer-events-none"
      type="checkbox"
      :id="name"
      :checked="value"
      @change="$emit('input', $event.target.checked)"
    />
    <span
      class="ml-2 pr-2.5 font-semibold text-gray-300"
      :class="{
        'text-sm py-1': size !== 'small',
        'text-xs': size === 'small',
      }"
      >{{ label }}</span
    >
    <div
      class="absolute bottom-full mb-1.5 right-0 bg-gray-800 font-semibold px-1 rounded text-xs py-0.5 text-gray-200 tabular-nums border border-gray-700 text-center opacity-0 transition-opacity group-hover:opacity-100 pointer-events-none w-48"
      v-if="tooltip"
    >
      {{ tooltip }}
    </div>
  </label>
</template>

<script>
export default {
  name: "Checkbox",
  props: ["label", "name", "value", "size", "tooltip"],
};
</script>
