<template>
  <div class="mt-2 px-4">
    <div class="grid grid-cols-4 gap-6 mt-4">
      <div v-for="(item, index) in data" :key="index" v-show="!item.hide">
        <div class="text-sm font-semibold text-gray-600">
          {{ item.label }}
        </div>
        <div
          class="text-sm font-bold text-gray-800"
          v-if="!Array.isArray(item.value)"
        >
          {{ item.value }}
        </div>
        <div v-else>
          <div
            class="text-sm font-bold text-gray-900"
            v-for="(value, index) in item.value"
            :key="index"
          >
            {{ value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SummaryData",
  props: ["label", "data"],
};
</script>
